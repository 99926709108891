import React, { useState, useEffect } from 'react';
import { Typography, Container } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import { db } from '../../firebase';
import OpenArticleContainer from '../../containers/OpenArticleContainer';
import { useStores } from '../../hooks/useStore';
import Posts from '../../data.json';
import styles from './validateview.module.css';
import Lottie from "lottie-react";
import check from "../../assets/lotties/check.json";

const ToBeValidated = ({ onValidated, onError }) => {
  useEffect(() => {
    const handleValidation = async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const alreadyValidated = searchParams.get('validated') === 'true';

      if (alreadyValidated) {
        onValidated();
        return;
      }

      const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
      const user = searchParams.get('user');
      try {
        await db.collection(`users/${user}/calendar`).add({
          timestamp,
          method: 'nfc'
        });
      } catch (error) {
        console.error('Error writing to Firestore', error);
        onError();
      }
      onValidated();
    };

    handleValidation();
  }, []); // empty dependency array

  return (
    <Container>
      <Typography variant="body1">
        Hold tight, while we're validating your intake
      </Typography>
    </Container>
  );
};

const Validated = ({ user }) => {
  const { postStore } = useStores();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    postStore.setPosts(Posts.posts);
    setIsLoading(false);
  }, [postStore]);

  // Get a random post from postStore, if available
  let randomPost;
  if (!isLoading && postStore.posts && postStore.posts.length > 0) {
    const randomIndex = Math.floor(Math.random() * postStore.posts.length);
    randomPost = postStore.posts[randomIndex];
  }

  if (isLoading || !randomPost) {
    return <div>Loading...</div>;
  }

  return (
    <Container className={styles.container}>

      <Lottie animationData={check} className={styles.animation} loop={false} />
      <div className={styles.wrapper}>
        <p className={styles.title}>Erledigt!</p>
        <p className={styles.sub}>Deine Eingabe wurde für Nutzer {user} validiert.</p>
      </div>
      <OpenArticleContainer post={randomPost} />
    </Container>
  );
};

const Error = () => {
  return (
    <Container>
      <Typography variant="body1">
        There was an error validating your intake.
      </Typography>
    </Container>
  );
};

const ValidateView = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [validated, setValidated] = useState(false);

  const onValidated = () => {
    setValidated(true);
  };

  const onError = () => {
    setValidated('error');
  };

  const searchParams = new URLSearchParams(window.location.search);
  const user = searchParams.get('user');

  useEffect(() => {
    if (user === null) {
      setValidated('error');
    } else {
      const searchParams = new URLSearchParams(window.location.search);
      searchParams.set('validated', validated.toString());
      navigate({ search: `?${searchParams.toString()}` });
    }
  }, [validated, navigate, user]);

  useEffect(() => {
    if (!validated) {
      setValidated(true);
    }
  }, [validated]);

  return (
    <>
      {validated === false && user !== null && <ToBeValidated onValidated={onValidated} onError={onError} />}
      {validated === true && <Validated user={user} />}
      {validated === 'error' && <Error />}
    </>
  );
};

export default ValidateView;
