import styles from "./contentcontainer.module.css";
import globalStyles from "../../globalstyles.module.css";

const renderContent = (content) => {
    return content.map((item, index) => {
        switch (item.type) {
            case 'subtitle':
                return <h2 key={index} className={globalStyles.subtitle}>{item.text}</h2>;
            case 'introductory':
                return <p key={index} className={globalStyles.introductory}>{item.text}</p>;
            case 'text':
                return <p key={index} dangerouslySetInnerHTML={{ __html: item.text }} className={globalStyles.text}></p>;
            case 'image':
                return <img key={index} src={item.src} alt={item.alt} className={styles.img} />;
            case 'subheading':
                return <h3 key={index} className={item.weight}>{item.text}</h3>;
            case 'video':
                return <video controls className={styles.video}><source src={item.src} type="video/mp4" /></video>;
            default:
                return null;
        }
    });
};

function ContentContainer({ post }) {

    return (
        <div className={styles.container}>
            {renderContent(post.content)}
        </div>
    );
}

export default ContentContainer;
