import React, { useState } from 'react';
import { useObserver } from 'mobx-react-lite';
import styles from "./tokenview.module.css";
import { getToken } from "../../firebase";
import OnboardingButton from '../../components/OnboardingButton';
import { useStores } from '../../hooks/useStore';
import { Container } from '@mui/system';

function TokenView() {
    const { userStore } = useStores();

    const [isTokenFound, setTokenFound] = useState(false);
    const [NotificationNext, setNotificationNext] = useState(true);

    async function getTokenAndSetState() {
        setNotificationNext(false)
        const data = await getToken(setTokenFound);
        if (data) {
            console.log("Token is", data);
            // userStore.setDeviceToken(data);
            userStore.updateToken(data);
            window.alert('Ihr "Geräte-Token" wurde erfolgreich aktualisiert.');
        } else {
        }
    }

    const handleAllowNotification = () => {
        console.log("Token found on reminder", isTokenFound);
        getTokenAndSetState();
    };

    return useObserver(() => (
        <Container style={{ paddingBottom: "60px" }}>
            <div className={styles.container}>
                <h1 className={styles.title}>Aktualisieren Sie Ihre Erinnerungse-instellungen.</h1>
                <p className={styles.descriptionSmall}>Klicken Sie auf die Schaltfläche unten, um Ihre Erinnerungseinstellungen zu aktualisieren.</p>
                <OnboardingButton type="next" buttonId="onboarding_notificationsdone" onClick={handleAllowNotification} label="Benachrichtigungen erlauben" state={NotificationNext} />
            </div>
        </Container>
    ));
}

export default TokenView;