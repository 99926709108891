import { observable, action, makeObservable } from "mobx";

class PostStore {
  posts = [];
  tags = [];
  selectedTags = [];

  constructor() {
    makeObservable(this, {
      posts: observable,
      tags: observable,
      selectedTags: observable,
      setPosts: action,
      setTags: action,
      setSelectedTags: action,
    });
  }

  setPosts(posts) {
    this.posts = posts;
    const allTags = [];
    posts.forEach(post => {
      post.tags.forEach(tag => {
        if (!allTags.includes(tag)) {
          allTags.push(tag);
        }
      });
    });
    this.setTags(allTags);
  }

  setTags(tags) {
    this.tags = tags;
  }

  setSelectedTags(tags) {
    this.selectedTags = tags;
  }
}

export default PostStore;
