import React, { useEffect, useState } from 'react';
import { Typography, Container, Button } from '@mui/material';
import GlobalStyles from '../../globalstyles.module.css';
import styles from './settingsview.module.css';
import HelpIcon from '../../assets/icons/help.svg';
import BugIcon from '../../assets/icons/bugs.svg';
import MedIcon from '../../assets/icons/medication.svg';
import HomeIcon from '../../assets/icons/homescreen.svg';
import ReminderIcon from '../../assets/icons/reminder.svg';
import { ChevronRightRounded } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import Modal from 'react-modal';
import { useStores } from '../../hooks/useStore';

const SettingsView = () => {

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [medication, setMedication] = useState(localStorage.getItem('userMed'));
  const { userStore } = useStores();

  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleMedChange = (event) => {
    setMedication(event.target.value)
    const value = event.target.value === '' ? null : event.target.value; // set to null if value is empty
    userStore.setMedication(value);
  };
  
  const handlSubmitMedication = async () => {
    await userStore.initFirebaseAndGTM();
    setIsModalOpen(false);
  }

  return (
    <Container style={{ paddingBottom: "65px", minHeight: 'calc(115vh - 60px)' }} >
      <h1 className={GlobalStyles.title}>
        Einstellungen
      </h1>
      <div style={{ marginBottom: "15px", fontWeight: "500", color: "#303030" }}>
        Deine Benutzer-ID: <span style={{ fontWeight: "bolder", color: "#6260E6" }}>{localStorage.getItem('userId')}</span>
      </div>
      <div className={styles.blocks}>
        <div className={styles.top}>
          <Link to={`/settings/reminders`} className={styles.block} >
            <img className={styles.icon} src={ReminderIcon} />
            <div>
              <p className={styles.tag}>Erinnerungen festlegen</p>
              <p className={styles.sub}>1 time/day</p>
            </div>
            <ChevronRightRounded className={styles.chevron} />
          </Link>
          {/* <a className={styles.block} href={`https://lilly-bug-report.webflow.io/faq?user=${localStorage.userId}`}>
            <img className={styles.icon} src={HomeIcon} />
            <div>
              <p className={styles.tag}>Zum Startbildschirm hinzufügen</p>
              <p className={styles.sub}>Füge die App deinem Startbildschirm hinzu, um das Beste daraus zu machen</p>
            </div>
            <ChevronRightRounded className={styles.chevron} />
          </a> */}
          <div className={styles.block} onClick={handleOpenModal}>
            <img className={styles.icon} src={MedIcon} />
            <div>
              <p className={styles.tag}>{localStorage.userMed}</p>
              <p className={styles.sub}>1 Mal/Tag</p>
            </div>
            <ChevronRightRounded className={styles.chevron} />
          </div>
        </div>
        <div className={styles.bottom}>
          <a className={styles.block} href={`https://pilly-bug-report.webflow.io/faq?user=${localStorage.userId}`}>
            <img className={styles.icon} src={HelpIcon} />
            <div>
              <p className={styles.tag}>Benötigen Sie Hilfe?</p>
              <p className={styles.sub}>Eine Lösung finden</p>
            </div>
            <ChevronRightRounded className={styles.chevron} />
          </a>

          <a className={styles.block} href={`https://pilly-bug-report.webflow.io/?user=${localStorage.userId}`}>
            <img className={styles.icon} src={BugIcon} />
            <div>
              <p className={styles.tag}>Einen Bug berichten</p>
              <p className={styles.sub}>Lassen Sie es uns wissen, wenn etwas nicht wie erwartet funktioniert</p>
            </div>
            <ChevronRightRounded className={styles.chevron} />
          </a>
        </div>
      </div>

      <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} style={{ overlay: { backgroundColor: 'rgba(0, 0, 0, .75)' }, content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', borderRadius: '20px', boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)', maxWidth: '80%', padding: '35px 15px 15px', } }}>
        <img src={MedIcon} style={{ margin: '0 auto 20px', display: 'block', width: '50px' }} />
        <h2 style={{ fontSize: '1.3rem', fontWeight: '500', marginBottom: '15px', textAlign: 'center', padding: "0px 10%" }}>Welches Medikament nimmst Du?</h2>
        <div style={{}}>
          <select className={styles.input} value={medication} onChange={handleMedChange}>
            <option value="Toremifene">Toremifene</option>
            <option value="Tamoxifen">Tamoxifen</option>
            <option value="unsure">Ich weiß nicht</option>
          </select>
          <Button variant="contained" id="pill_added_manually-confirm" color="primary" onClick={handlSubmitMedication} style={{ textTransform: 'none', fontSize: '1rem', fontWeight: '600', width: '100%', marginRight: '10px', backgroundColor: '#6260E6', color: '#FFF', marginBottom: '5px', borderRadius: '10px' }}>
            Bestätigen
          </Button>
          <Button variant="outlined" color="primary" onClick={handleCloseModal} style={{ textTransform: 'none', fontSize: '1rem', fontWeight: '400', width: '100%', border: 'none', color: '#8B8B90' }}>
            Abbrechen
          </Button>
        </div>
      </Modal>

    </Container>
  );
};

export default SettingsView;
