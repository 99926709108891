import { useState } from 'react';
import styles from './calendartoggle.module.css'; // import your CSS file with custom styles
import calendarIcon from '../../assets/icons/calendar.svg';
import weekIcon from '../../assets/icons/week.svg';
import exitIcon from '../../assets/icons/exit.svg';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';

const CalendarToggle = observer(() => {
    const [isChecked, setIsChecked] = useState(false);
    const { uiStore } = useStores();

    const handleToggle = () => {
        uiStore.toggleCalendar();
    };

    return (
        <>
            {/* <img src={calendarIcon} alt="calendar icon" className={styles.icon} /> */}
            <label className={styles.toggle}>
                <button type="checkbox" className={styles.checkbox} checked={isChecked} onClick={handleToggle} id='home_date'/>
                <div className={styles.icon} >
                    <img src={uiStore.calendarOpened ? exitIcon : calendarIcon} alt="" />
                    </div>
            </label>
        </>
    );
})

export default CalendarToggle;