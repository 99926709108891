import { observable, action, makeObservable } from "mobx";
import { collection, getDocs } from "firebase/firestore";
import { db } from "../firebase";

class MantraStore {
  mantras = [];

  constructor() {
    makeObservable(this, {
      mantras: observable,
      setMantras: action,
    });
  }

  async fetchMantras() {
    const querySnapshot = await getDocs(collection(db, "mantras"));
    const mantras = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      text: doc.data().text,
    }));
    this.setMantras(mantras);
  }

  setMantras(mantras) {
    this.mantras = mantras;
  }
}

export default MantraStore;
