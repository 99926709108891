import React, { useRef, useState, useEffect } from 'react';
import { observer } from 'mobx-react';
import SwiperCore, { Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/swiper.min.css';
import styles from './onboardingview.module.css';
import { useStores } from '../../hooks/useStore';
import IdScreen from '../../OnboardingScreens/IdScreen';
import OnboardingButton from '../../components/OnboardingButton';
import MedScreen from '../../OnboardingScreens/MedScreen';
import TermsScreen from '../../OnboardingScreens/TermsScreen';
import NfcScreen from '../../OnboardingScreens/NfcScreen';
import ReminderScreen from '../../OnboardingScreens/ReminderScreen';
import ScheduleScreen from '../../OnboardingScreens/ScheduleScreen';
import InstallScreen from '../../OnboardingScreens/InstallScreen';
import Intro1 from '../../assets/images/onboarding/intro1.svg';
import Intro2 from '../../assets/images/onboarding/intro2.svg';
import Intro3 from '../../assets/images/onboarding/intro3.svg';
import Intro4 from '../../assets/images/onboarding/intro4.svg';
import { requestNotificationPermission } from '../../utils/notification';
import { getToken } from "../../firebase";

SwiperCore.use([Pagination]);

function IntroductionSlide({ title, description, img, number, warning }) {
  const dots = [1, 2, 3, 4];

  return (
    <div className={styles.introSlide}>

      <img src={img} alt="" className={styles.introImg} />
      <h1 className={styles.introTitle}>{title}</h1>
      { warning ? 
        <p className={styles.introWarning}>{warning}</p> : false
      }
      <p className={styles.introDescription}>{description}</p>
      <div className={styles.dotContainer}>
        {dots.map((dotNumber) => (
          <div
            key={`dot-${dotNumber}`}
            className={`${styles.dot} ${dotNumber === number ? styles.active : ''
              }`}
          />
        ))}
      </div>
    </div>
  );
}

function OnboardingView() {
  const { userStore } = useStores();
  const swiperRef = useRef(null);
  const [showInstallScreen, setShowInstallScreen] = useState(false);
  const [isReminderScreenActive, setIsReminderScreenActive] = useState(false);
  const [NotificationNext, setNotificationNext] = useState(true);

  useEffect(() => {
    // Parse URL parameters
    const urlSearchParams = new URLSearchParams(window.location.search);
    const keyParam = urlSearchParams.get('key');

    // Check if the key parameter equals 'urqzKE22'
    if (keyParam === 'urqzKE22') {
      setShowInstallScreen(false);
    } else {
      // Continue with your existing logic to determine install screen visibility
      const isRunningInStandaloneMode = window.matchMedia('(display-mode: standalone)').matches;
      setShowInstallScreen(!isRunningInStandaloneMode);
    }
  }, []);

  const handleNext = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
    }
  };

  const handleNextPlusOne = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slideNext();
      swiperRef.current.swiper.slideNext();
    }
  };
  
  const handlePrev = () => {
    if (swiperRef.current && swiperRef.current.swiper) {
      swiperRef.current.swiper.slidePrev();
    }
  };

  const handleCheckUser = async () => {
    // const userId = JSON.parse(localStorage.getItem("userId"));
    const userId = localStorage.getItem("userId");

    if (await userStore.checkIfUserExists(userId)) {
      // If user exists, load data and navigate to previous slide
      await userStore.loadUserDataFromFirebase();
      console.log('User exists');
      window.location.reload();
    } else {
      // If user doesn't exist, navigate to next slide
      handleNext();
    }

  };

  const [isTokenFound, setTokenFound] = useState(false);

  async function getTokenAndSetState() {
    setNotificationNext(false)
    const data = await getToken(setTokenFound);
    if (data) {
      console.log("Token is", data);
      userStore.setDeviceToken(data);
      handleNext();
    } else {
      handleNextPlusOne();
    }
  }

  const handleGoNotifications = () => {
    const isAndroid = /Android/.test(navigator.userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
    const isMacOS = /Mac/.test(navigator.platform);

    if (isAndroid || isMacOS) {
      setIsReminderScreenActive(true);
    } else if (isIOS) {
      setIsReminderScreenActive(false);
    }

    handleNext();
  };

  const handleAllowNotification = () => {
    console.log("Token found on reminder", isTokenFound);
    getTokenAndSetState();
  };

  const handleComplete = async () => {
    userStore.setOnboarded(true);
    await userStore.initFirebaseAndGTM();
    await userStore.changeNotifactions();
    // userStore.saveState();
    window.location.reload();
  };

  const swiperParams = {
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    spaceBetween: 30,
    loop: false,
    navigation: false,
    allowTouchMove: false, // disable swiping
  };

  const isNextButtonDisabled = !(userStore.intakeTime &&
    (!userStore.forgetReminder || (userStore.forgetReminder && userStore.forgetTime))
  );

  const introductionSlides = [
    {
      img: Intro1,
      title: 'Willkommen bei Pilly!',
      description: 'Pilly hilft Dir Deine tägliche Tabletteneinnahme im Blick zu behalten.'
    },
    {
      img: Intro2,
      title: 'Scanne bei jeder Tablette.',
      description: 'Nutze den ablösbaren Funk-Aufkleber um Deine Tabletteneinnahme zu festzuhalten.'
    },
    {
      img: Intro3,
      title: "Hab' ich heute schon ...?",
      warning: "Erinnerungen sind nur auf Android-Telefonen möglich",
      description: 'Sieh Deine letzte Tabletteneinnahme auf einen Blick und wähle, ob Du erinnert werden möchtest wenn Du es vergessen hast - oder vorsichtshalber jedes Mal.'
    },
    {
      img: Intro4,
      title: 'Du hast einen Erfolgssträhne?',
      description: 'Schau Dir Deinen Erfolg auf einen Blick an und finde wertvolle Informationen.'
    }
  ];

  return (
    <div className={styles.container}>
      <Swiper {...swiperParams} ref={swiperRef}>
        {showInstallScreen && (
          <SwiperSlide>
            <div>
              <InstallScreen />
            </div>
          </SwiperSlide>
        )} 

        {introductionSlides.map((slide, index) => (
          <SwiperSlide key={`introduction-${index}`}>
            <div className={styles.slide}>
              <div style={{marginTop: "30px"}}>
              <OnboardingButton type="back" onClick={handlePrev} isHidden={index + 1 === 1} />
              <IntroductionSlide number={index + 1} title={slide.title} description={slide.description} img={slide.img} warning={slide.warning} />
              </div>
              <div className={styles.buttonContainer}>
                <OnboardingButton type="next" onClick={handleNext} label="Weiter" state={true} />
              </div>
            </div>
          </SwiperSlide>
        ))}
        <SwiperSlide>
          <div className={styles.slide}>
            <div style={{marginTop: "30px"}}>
            <OnboardingButton type="back" onClick={handlePrev} />
            <IdScreen className={styles.test} />
            </div>
            <div className={styles.buttonContainer}>
              <OnboardingButton type="next" buttonId="onboarding_userid" onClick={handleCheckUser} label="Einloggen" state={userStore.userId} />
            </div>

          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.slide}>
                <div style={{marginTop: "30px"}}>
            <OnboardingButton type="back" onClick={handlePrev} />
            <NfcScreen />
            </div>
            <div className={styles.buttonContainer}>
              <OnboardingButton type="later" buttonId="nfc_attached_later" onClick={handleGoNotifications} label="Später einrichten" />
              <OnboardingButton type="next" buttonId="nfc_attached" onClick={handleGoNotifications} label="Ich habe den Funk-Aufkleber angebracht" state={true} />
            </div>
          </div>
        </SwiperSlide>

        {isReminderScreenActive && (
          <>
            <SwiperSlide>
              <div className={styles.slide}>
                <div style={{marginTop: "30px"}}>
                <OnboardingButton type="back" onClick={handlePrev} />
                <ReminderScreen />
                </div>
                <div className={styles.buttonContainer}>
                  <OnboardingButton type="later" buttonId="onboarding_notificationslater" onClick={handleNextPlusOne} label="Später einrichten" />
                  <OnboardingButton type="next" buttonId="onboarding_notificationsdone" onClick={handleAllowNotification} label="Benachrichtigungen erlauben" state={NotificationNext} />
                </div>
              </div>
            </SwiperSlide>
            <SwiperSlide>
              <div className={styles.slide}>
              <div style={{marginTop: "30px"}}>
                <OnboardingButton type="back" onClick={handlePrev} />
                <ScheduleScreen />
                </div>
                <div className={styles.scrolledBtns}>
                  <OnboardingButton type="later" buttonId="schedule_reminder_later" onClick={handleNext} label="Später einrichten" />
                  <OnboardingButton type="next" buttonId="schedule_reminder" onClick={handleNext} label="Weiter" state={!isNextButtonDisabled} />
                </div>
              </div>
            </SwiperSlide>
          </>
        )}
        <SwiperSlide>
          <div className={styles.slide}>
            <div style={{marginTop: "30px"}}>
            <OnboardingButton type="back" onClick={handlePrev} />
            <MedScreen />
            </div>
            <div className={styles.buttonContainer}>
              <OnboardingButton type="next" buttonId="onboarding_medicationdone" onClick={handleNext} label="Weiter" state={true} />
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className={styles.slide}>
            <div style={{marginTop: "30px"}}>
            <OnboardingButton type="back" onClick={handlePrev} />
            <TermsScreen />
            </div>
            <div className={styles.buttonContainer}>
              <OnboardingButton type="next" buttonId="onboarding_termsdone" onClick={handleComplete} label="Confirm" state={userStore.agreedToTerms} />
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </div>
  );
}

export default observer(OnboardingView);