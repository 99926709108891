import React from 'react';
import { useObserver } from 'mobx-react-lite';
import styles from "./filtercontainer.module.css";
import { useStores } from '../../hooks/useStore';
import FilterTag from '../../components/FilterTag';

function FilterContainer() {
    const { uiStore, postStore } = useStores();
    const tags = postStore.tags;

    return useObserver(() => (
        <div className={styles.container}>
            {
                tags.map(tag => (
                    <FilterTag
                      key={tag}
                      tag={tag}
                      filter={true}
                      color={true}
                    />
                ))
            }
        </div>
    ));
}

export default FilterContainer;
