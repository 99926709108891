import React, { useEffect, useMemo, useState } from 'react';
import styles from './blogscontainer.module.css';
import ContentBlock from '../../components/ContentBlock';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';

const BlogsContainer = observer(() => {
  const { postStore } = useStores();
  const selectedTags = postStore.selectedTags;

  const filteredPosts = useMemo(() => {
    return selectedTags.length === 0 ? postStore.posts :
      postStore.posts.filter(post => selectedTags.every(tag => post.tags.includes(tag)));
  }, [selectedTags, postStore.posts]);

  const [shuffledPosts, setShuffledPosts] = useState(filteredPosts);

  useEffect(() => {
    let shuffled = [...filteredPosts];

    // Shuffle the filtered posts array
    for (let i = shuffled.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
    }

    setShuffledPosts(shuffled);
  }, [filteredPosts]);

  return (
    <div className={styles.container}>
      {shuffledPosts.length > 0 ? (
        shuffledPosts.map(post => (
          <ContentBlock key={post.id} post={post} />
        ))
      ) : (
        <>
        <p className={styles.emptyTitle}>Hoppla!</p>
        <p className={styles.empty}>Es scheint keine Artikel mit den ausgewählten Tags zu geben.</p>
        </>
      )}
    </div>
  );
});

export default BlogsContainer;
