import React, { useState, useEffect } from "react";
import { getToken } from "../../firebase";
import styles from '../onboarding.module.css'
import NotificationImg from '../../assets/images/onboarding/notifications.svg'
import { useStores } from '../../hooks/useStore';

function ReminderScreen() {
  return (
    <div className={styles.container}>
      <h1  className={styles.title}>Benachrichtigungen und Erinnerungen</h1>
      <p className={styles.description}>Erlaube Benachrichtigungen um Erinnerungen einzurichten, die Dich an Deine täglich Tabletteneinnahme erinnern.</p>
      <img src={NotificationImg} className={styles.img}></img>
    </div>
  );
}

export default ReminderScreen;
