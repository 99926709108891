import { useState, useEffect } from 'react';
import styles from './daycheck.module.css'; // import your CSS file with custom styles
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';
import { Check } from '@mui/icons-material';
import moment from 'moment'; // import the moment.js library

import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';

const DayCheck = observer(({day, timestamps}) => {
    const [isChecked, setIsChecked] = useState(false);
    const { uiStore } = useStores();

    useEffect(() => {
        const dayTimestamps = timestamps.map((timestamp) => moment(timestamp, 'M/D/YYYY, h:mm:ss A').format('M/D/YYYY'));
        setIsChecked(dayTimestamps.includes(day)); // check if the day is included in the timestamps array when the component loads
    }, [timestamps, day]);

    useEffect(() => {
        if (isChecked) {
            // console.log(day); // log the checked day to the console
        }
    }, [isChecked, day]);

    const containerStyle = {
        backgroundColor: isChecked ? '#6260E6' : 'rgb(246, 246, 246)', // set the background color of the container to red if isChecked is true, otherwise set it to transparent
    };

    return (
        <>
            <div className={styles.container} style={containerStyle}>
                {isChecked && <Check className={styles.icon} />} {/* display the check icon if isChecked is true */}
            </div>
        </>
    );
})

export default DayCheck;
