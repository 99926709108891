import { useState } from 'react';
import styles from './backbutton.module.css'; // import your CSS file with custom styles
import { observer } from 'mobx-react-lite';
import { ChevronLeft } from '@mui/icons-material';
import { Link } from 'react-router-dom';

const BackButton = observer(({location}) => {

    return (
        <Link className={styles.container} to={location}>
            <ChevronLeft/>
        </Link>
    );
})

export default BackButton;