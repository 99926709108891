import UiStore from "./UiStore";
import { configure } from "mobx";
import UserStore from "./UserStore";
import PostStore from "./PostStore";
import MantraStore from "./MantraStore";

configure({ enforceActions: "observed" });

class RootStore {
  constructor() {
    this.uiStore = new UiStore();
    this.userStore = new UserStore();
    this.postStore = new PostStore();
    this.mantraStore = new MantraStore();
  }
}

export default RootStore;