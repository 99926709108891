import React, { useEffect, useState } from 'react';
import styles from './schedule.module.css'
import { useStores } from '../../hooks/useStore';
import GlobalStyles from '../../globalstyles.module.css';
import { Container } from '@mui/system';
import OnboardingButton from '../../components/OnboardingButton';
import { Link } from 'react-router-dom';
import VotingForm from '../../containers/VotingForm';
import { getToken } from '../../firebase';
import NotificationImg from '../../assets/images/onboarding/notifications.svg'

function ScheduleSettings() {
  const { userStore } = useStores();
  const [intakeTime, setIntakeTime] = useState(
    localStorage.getItem('intakeTime') ? decodeURIComponent(localStorage.getItem('intakeTime')) : ''
  );
  const [forgetTime, setForgetTime] = useState(
    localStorage.getItem('forgetTime') ? decodeURIComponent(localStorage.getItem('forgetTime')) : ''
  );
  const [everydayReminder, setEverydayReminder] = useState(userStore.everydayReminder);
  const [forgetReminder, setForgetReminder] = useState(userStore.forgetReminder);
  const [showForgetTime, setShowForgetTime] = useState(localStorage.getItem('forgetReminder'));
  const [isIOS, setIos] = useState(false);
  const storedDeviceToken = localStorage.getItem('deviceToken');
  const initialNotificationAllowed = storedDeviceToken !== null && storedDeviceToken !== 'false';

  const [notificationAllowed, setNotificationAllowed] = useState(initialNotificationAllowed);

  const [NotificationNext, setNotificationNext] = useState(true);

  const isNextButtonDisabled = !(userStore.intakeTime &&
    (!userStore.forgetReminder || (userStore.forgetReminder && userStore.forgetTime))
  );

  const handleEveryDayChange = (event) => {
    const value = event.target.checked;
    setEverydayReminder(value);
    userStore.setEverydayReminder(value);
  };

  const [isTokenFound, setTokenFound] = useState(false);

  async function getTokenAndSetState() {
    setNotificationNext(false)
    const data = await getToken(setTokenFound);
    if (data) {
      console.log("Token is", data);
      // userStore.setDeviceToken(data);
      userStore.updateToken(data);
      setNotificationAllowed(true)
    } else {
    }
  }

  const handleAllowNotification = () => {
    console.log("Token found on reminder", isTokenFound);
    getTokenAndSetState();
  };

  useEffect(() => {
    const isAndroid = /Android/.test(navigator.userAgent);
    const isIOS = /iPad|iPhone|iPod/.test(navigator.platform);
    const isMacOS = /Mac/.test(navigator.platform);

    if (isAndroid || isMacOS) {
      setIos(false);
      // getTokenAndSetState();
    } else if (isIOS) {
      setIos(true);
    }
  })


  const handleForgetChange = (event) => {
    const value = event.target.checked;
    setForgetReminder(value);
    userStore.setForgetReminder(value);
    setShowForgetTime(value);
    if (value === false) {
      setForgetTime(null);
      userStore.setForgetTime(null);
    }

  };

  const handleInputIntake = (event) => {
    const value = event.target.value;
    setIntakeTime(value);
    userStore.setIntakeTime(value);
  };

  const handleInputForget = (event) => {
    const value = event.target.value;
    setForgetTime(value);
    userStore.setForgetTime(value);
  };

  const handlSubmitSchedule = async () => {
    await userStore.changeNotifactions();
    window.alert('Ihre Uhrzeit wurde erfolgreich aktualisiert!');
  }

  return (
    <Container style={{ paddingBottom: "60px" }}>
      <Link to={`/settings`}>
        <OnboardingButton type="back" />
      </Link>
      {
        isIOS ?
          <div className={styles.container}>
            <h1 className={styles.title}>Erinnerungen sind derzeit für iOS nicht verfügbar.</h1>
            <p className={styles.descriptionSmall}>Könnten Push-Benachrichtigungen helfen, die Einnahme von Medikamenten nicht zu vergessen?</p>
            <div className={styles.fieldsContainer}>
              <VotingForm post={'iosForNotifications'} />
            </div>
          </div>
          : notificationAllowed ?
            <>
              <div className={styles.container}>
                <h1 className={styles.title}>Erinnerungen festlegen</h1>
                <p className={styles.descriptionSmall}>Wann nimmst Du normalerweise Deine Tablette ein?</p>
                <div className={styles.fieldsContainer}>
                  <label className={styles.label}>Wann nimmst Du normalerweise<br />Deine Tablette ein?
                    <input
                      type="time"
                      placeholder="08:00"
                      value={intakeTime}
                      onChange={handleInputIntake}
                      className={styles.timeInput}
                    />
                  </label>
                  <br />

                  <label className={styles.checkbox} >
                    Erinnere mich jeden Tag daran.
                    <input
                      type="checkbox"
                      checked={everydayReminder}
                      onChange={handleEveryDayChange}
                      className={styles.checkboxBox}
                    />
                  </label>

                  <hr className={GlobalStyles.devider} />

                  <label className={styles.checkbox}>
                    Erinnere mich, falls ich vergessen habe zu scannen.
                    <p></p>
                    <input
                      id="forget-check"
                      type="checkbox"
                      checked={forgetReminder}
                      onChange={handleForgetChange}
                      className={styles.checkboxBox}
                    />
                  </label>
                  {showForgetTime && (
                    <label className={styles.labelSmall} id="forget-time">Um welche Uhrzeit möchtest du erinnert werden?
                      <input
                        id="forgetTime"
                        type="time"
                        placeholder="08:15"
                        value={forgetTime}
                        onChange={handleInputForget}
                        className={styles.timeInput}
                      />
                    </label>
                  )}
                </div>
              </div>
              <div style={{ marginTop: "25px" }}>
                <OnboardingButton onClick={handlSubmitSchedule} type="next" buttonId="schedule_reminder" label="Weiter" state={!isNextButtonDisabled} />
              </div>
            </> :
            <>
              <div className={styles.container}>
                <h1 className={styles.title}>Benachrichtigungen und Erinnerungen</h1>
                <p className={styles.description}>Erlaube Benachrichtigungen um Erinnerungen einzurichten, die Dich an Deine täglich Tabletteneinnahme erinnern.</p>
                <img src={NotificationImg} className={styles.img}></img>

                <div style={{ marginTop: "25px" }}>
                <OnboardingButton type="next" buttonId="onboarding_notificationsdone" onClick={handleAllowNotification} label="Benachrichtigungen erlauben" state={NotificationNext} />
                </div>
              </div>
            </>
      }

    </Container>
  );
}

export default ScheduleSettings;
