import { observable, action, makeObservable } from "mobx";

class UiStore {
    calendarOpened = false;
    currentDay = undefined;
    location = '/';
    isTodayValidated = false;
    intakeTime = false;

    week = {
        monday: 'Mo',
        tuesday: 'Di',
        wednesday: 'Mi',
        thursday: 'Do',
        friday: 'Fr',
        saturday: 'Sa',
        sunday: 'So'
    };
    selectedTags = [];

    constructor() {
        makeObservable(this, {
            isTodayValidated: observable,
            calendarOpened: observable,
            toggleCalendar: action,
            currentDay: observable,
            setCurrentDay: action,
            selectedTags: observable,
            updateSelectedTags: action,
            location: observable,
            setLocation: action,
            intakeTime: observable,
            setIntakeTime: action,
            setIsTodayValidated: action
        });
    }

    toggleCalendar() {
        this.calendarOpened = !(this.calendarOpened);
    }

    setIsTodayValidated(value) {
        this.isTodayValidated = value;
        // console.log(this.isTodayValidated)
    }

    setIntakeTime(value) {
        this.intakeTime = value;
        // console.log(this.intakeTime)
    }

    setCurrentDay() {
        this.currentDay = new Date().toLocaleString('de-DE', { weekday: 'short' });
    }

    updateSelectedTags(tags) {
        this.selectedTags.replace(tags);
    }

    setLocation(location) {
        // console.log(location)
        this.location = location;
    }
}

export default UiStore;
