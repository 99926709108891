import React, { useEffect, useState } from 'react';
import { Typography, Container } from '@mui/material';
import GlobalStyles from '../../globalstyles.module.css';
import styles from './infoview.module.css';
import FilterContainer from '../../containers/FilterContainer';
import BlogsContainer from '../../containers/BlogsContainer';
import { useStores } from '../../hooks/useStore';
import Posts from '../../data.json';
import { observer } from 'mobx-react-lite';

const InfoView = observer(() => {
  const { postStore } = useStores();
  const [isDataLoaded, setIsDataLoaded] = useState(false);

  useEffect(() => {
    postStore.setPosts(Posts.posts);
    setIsDataLoaded(true);
  }, [postStore]);

  const handleTagSelection = (tags) => {
    postStore.setSelectedTags(tags);
  };

  return (
    <Container style={{ paddingBottom: "60px" }}>
      <h1 className={GlobalStyles.title}>Information</h1>

      {isDataLoaded && (
        <div className={styles.filter}>
          <FilterContainer
            selectedTags={postStore.selectedTags}
            onTagSelect={handleTagSelection}
          />
        </div>
      )}

      <BlogsContainer selectedTags={postStore.selectedTags} />
    </Container>
  );
});

export default InfoView;
