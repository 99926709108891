import firebase from 'firebase/compat/app';
import 'firebase/compat/analytics';
import 'firebase/compat/firestore';
import "firebase/compat/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyCqgWXRo5VYPnugJB5vZsy70ToRKbZt4Gc",
  authDomain: "lilly-c51cf.firebaseapp.com",
  databaseURL: "https://lilly-c51cf-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "lilly-c51cf",
  storageBucket: "lilly-c51cf.appspot.com",
  messagingSenderId: "27836513921",
  appId: "1:27836513921:web:5fe5785f335c6e2887d780",
  measurementId: "G-F4EHW9HHMF"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();

export const db = firebase.firestore();

const messaging = firebase.messaging();

const { REACT_APP_VAPID_KEY } = process.env;
const publicKey = REACT_APP_VAPID_KEY;

export const getToken = async (setTokenFound) => {
  let currentToken = "";

  try {
    currentToken = await messaging.getToken({ vapidKey: publicKey });
    if (currentToken) {
      setTokenFound(true);
    } else {
      setTokenFound(false);
    }
  } catch (error) {
    console.log("An error occurred while retrieving token. ", error);
  }

  return currentToken;
};

export const onMessageListener = () =>
  new Promise((resolve) => {
    messaging.onMessage((payload) => {
      resolve(payload);
    });
  });
