import React from 'react';
import { Typography, Container } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
import { useObserver } from 'mobx-react-lite';

import styles from "./blogview.module.css";
import globalStyles from '../../globalstyles.module.css'

import { useStores } from '../../hooks/useStore';
import BackButton from '../../components/BackButton';
import { Link, useParams } from 'react-router-dom';
import blogPosts from '../../data.json';
import FilterTag from '../../components/FilterTag';
import ContentContainer from '../../containers/ContentContainer';
import { useEffect } from 'react';
import VotingForm from '../../containers/VotingForm';

function BlogView() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  // console.log('match',props)
  const { postId } = useParams();
  const post = blogPosts.posts.find(p => p.id.toString() === postId);

  return useObserver(() => (
    <div className={styles.container} style={{ paddingBottom: "40px", backgroundImage: `linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, 0.4) 23.26%, rgba(0, 0, 0, 0) 100%), url(${post.thumbnail})` }}>
      <Container style={{paddingTop: "30px"}}>
        <BackButton location={'/info'} />
      </Container>
      <div style={{ backgroundColor: "white", height: "100%" }} className={styles.textBlock}>
        <Container>
          <h1 className={styles.title}>{post.title}</h1>
          <span className={globalStyles.text}>{post.description}</span>

          <div className={styles.tagBox}>
            {post.tags.map(tag => (
              <FilterTag tag={tag} clickable={false} key={tag} />
            ))}
          </div>

          <ContentContainer post={post} />

          <VotingForm post={post.id} />

        </Container>
      </div>

    </div >
  ));
};

export default BlogView;
