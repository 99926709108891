import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, useLocation, useNavigate } from "react-router-dom";
import { observer } from 'mobx-react';

import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import { Home, Article, Settings, AddBox } from '@mui/icons-material';

import HomeView from './views/HomeView';
import SettingsView from './views/SettingsView';
import InfoView from './views/InfoView';
import { useStores } from './hooks/useStore';
import ScanView from './views/ScanView';
import BlogView from './views/BlogView';
import ValidateView from './views/ValidateView';
import OnboardingView from './views/OnboardingView';
import CMTest from './views/CMTest';
import ScheduleSettings from './SettingsScreens/ScheduleScreen';
import TokenView from './views/TokenView';

const Layout = observer(() => {
  const { userStore, mantraStore } = useStores();
  mantraStore.fetchMantras()
  const [value, setValue] = React.useState('/');
  const [isValidateView, setValidate] = useState(false);
  const navigate = useNavigate(); // get the navigate function from the router

  const handleChange = (event, newValue) => {
    setValue(newValue); // update the state with the new value
    navigate(newValue); // navigate to the new route
  };


  const location = useLocation();
  // const isValidateView = location.pathname === '/validate';
  // const isValidateView = false;

  // console.log(isValidateView)

  const [isLoading, setIsLoading] = useState(true);
  const [userExists, setUserExists] = useState(false);

  useEffect(() => {

    if (location.pathname === '/validate') {
      setValidate(true);
    } else if (location.pathname === '/newtoken') {
      setValidate(true);
    }

    const urlParams = new URLSearchParams(window.location.search);
    const userIdFromUrl = urlParams.get('user');

    // Check if userId is present in URL query parameters
    if (userIdFromUrl) {
      console.log("user found in url")
      userStore.setUserId(userIdFromUrl);
      userStore.loadDataFromFirestore(userIdFromUrl).then((userExists) => {
        setIsLoading(false);
        setUserExists(userExists);
        if (userExists) {
          console.log('user exists')
        } else {
          console.log('user does not exist')
        }
      });
    } else {
      // Check if userId is stored in local storage
      const userIdFromStorage = localStorage.getItem('userId');
      if (userIdFromStorage && userIdFromStorage !== 'undefined' && userIdFromStorage !== 'null') {
        console.log("user found in storage")
        userStore.setUserId(userIdFromStorage);
        userStore.loadDataFromFirestore(userIdFromStorage).then((userExists) => {
          if (userStore.userOnboarded) {
            setIsLoading(false);
            setUserExists(userExists);
            if (userExists) {
              console.log('user exists')
            } else {
              console.log('user does not exist')
            }
          } else {
            setIsLoading(false);
            setUserExists(false);
          }
        });
      } else {
        console.log("user will be onboarding")
        setIsLoading(false);
        setUserExists(false);
      }
    }
  }, [userStore]);

  const currentPath = window.location.pathname;

  if (isLoading) {
    // Show loading indicator
    return <div>Loading...</div>;
  } else if (!userExists) {

    return <OnboardingView />;

  } else {
    // Show layout if user exists and is onboarded
    return (
      <div style={{ padding: "30px 0" }}>
        <Routes>
          <Route path="/" element={<HomeView />} />
          <Route path="/scan" element={<ScanView />} />
          <Route path="/info" element={<InfoView />} />
          <Route path="/info/:postId" element={<BlogView />} />
          <Route path="/settings" element={<SettingsView />} />
          <Route path="/settings/reminders" element={<ScheduleSettings />} />
          <Route path="/settings/install" element={<ScheduleSettings />} />
          <Route path="/validate" element={<ValidateView />} />
          <Route path="/newtoken" element={<TokenView />} />
        </Routes>

        {!isValidateView && (
          <BottomNavigation
            value={value}
            onChange={handleChange}
            showLabels
            sx={{
              position: 'fixed',
              bottom: 0,
              left: 0,
              right: 0,
              borderTop: 0.8,
              paddingBottom: 3,
              borderColor: '#B9B9BB',
              backgroundColor: 'rgb(98, 96, 230)',
              "& .MuiBottomNavigationAction-root": {
                color: "rgba(255, 255, 255, 0.5)",
              },
              "& .Mui-selected": {
                color: "white !important",
              },
            }}
          >
            <BottomNavigationAction id='navigation_home' label="Home" value="/" icon={<Home style={{ marginBottom: "3px" }} />} to="/" />
            <BottomNavigationAction id='navigation_tappill' label="Scan" value="/scan" icon={<AddBox style={{ marginBottom: "3px" }} />} to="/scan" />
            <BottomNavigationAction id='navigation_information' label="Information" value="/info" icon={<Article style={{ marginBottom: "3px" }} />} to="/info" />
            <BottomNavigationAction id='navigation_settings' label="Einstellungen" value="/settings" icon={<Settings style={{ marginBottom: "3px" }} />} to="/settings" />
          </BottomNavigation>

        )}
      </div>
    );
  }
});

const App = observer(() => (
  <Router>
    <Layout />
  </Router>
));

export default App;
