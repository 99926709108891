import React, { useEffect, useState } from "react";
import { Typography, Container } from "@mui/material";
import { observer } from "mobx-react-lite";
import { collection, getDocs } from "firebase/firestore";

import DayView from "../DayView";
import CalendarView from "../CalendarView";
import StreakContainer from "../../containers/StreakContainer";
import CalendarToggle from "../../components/CalendarToggle";
import TimestampsView from "../TimestampsView";
import InstallContainer from "../../containers/InstallContainer";
import WeekContainer from "../../containers/WeekContainer";

import { useStores } from "../../hooks/useStore";
import { db } from "../../firebase";
import styles from "./homeview.module.css";
import GlobalStyles from "../../globalstyles.module.css";

const HomeView = observer(() => {
  const { uiStore, userStore } = useStores();
  const [data, setData] = useState(null);

  useEffect(() => {
    const fetchItems = async () => {
      const querySnapshot = await getDocs(
        collection(db, `users/${userStore.userId}/calendar`)
      );
      const docsData = querySnapshot.docs.map((doc) => doc.data());
      setData(docsData);
    };
    fetchItems();

    // Initialize Google Tag Manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'home_loaded',
      'user_id': localStorage.getItem('userId')
    });

    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-NCTFF5X');
  }, [userStore.userId]);

  if (!data) {
    return (
      <>
        <div className={styles.container}>
          <WeekContainer onlyLabels={true} />
        </div>
        <p>Loading...</p>
      </>
    );
  }

  const timestamps = data.map((docData) => docData.timestamp);
  const currentTime = new Date().getHours();
  let title = "Guten<br>Morgen!";
  if (currentTime >= 12 && currentTime < 18) {
    title = "Guten Tag!";
  } else if (currentTime >= 18) {
    title = "Guten<br>Abend!";
  }


  return (
    <>
      <Container className={styles.container} style={{ minHeight: 'calc(115vh - 60px)'}}>
        <div className={styles.streak}>
          <p className={styles.title} dangerouslySetInnerHTML={{ __html: title }}></p>
          <div className={styles.toggle}>
            <CalendarToggle />
          </div>
        </div>

        {uiStore.calendarOpened ? (
          <TimestampsView timestamps={timestamps} />
        ) : (
          <>
            <DayView timestamps={timestamps} />

          </>
        )}
      </Container>
    </>
  );
});

export default HomeView;
