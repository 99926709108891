import React, { useState, useEffect } from 'react';
import { Typography, Container } from '@mui/material';
import DailyPill from '../../components/DailyPill';
import Lottie from "lottie-react";
import tapAnimation from "../../assets/lotties/tap-nfc.json";
import manualIcon from "../../assets/images/app/manual.svg";
import GlobalStyles from '../../globalstyles.module.css';
import styles from './scanview.module.css';
import { Button } from '@mui/material';
import Modal from 'react-modal';
import { useStores } from '../../hooks/useStore';
import { db } from '../../firebase';

const ScanView = () => {
  const { userStore, uiStore } = useStores();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isTodayValidated, setIsTodayValidated] = useState(false);

  useEffect(() => {
    const today = new Date().toLocaleDateString('en-US', { timeZone: 'Europe/Berlin' });
    const query = db.collection(`users/${userStore.userId}/calendar`);
    query.get().then((querySnapshot) => {
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (docData.timestamp.includes(today)) {
          setIsTodayValidated(true);
          uiStore.setIsTodayValidated(true);

        }
      });
    }).catch((error) => {
      console.error('Error fetching documents: ', error);
    });
  }, [userStore.userId]);


  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleValidateIntake = async () => {
    const timestamp = new Date().toLocaleString('en-US', { timeZone: 'Europe/Berlin' });
    const time = new Date().toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
    try {
      await db.collection(`users/${userStore.userId}/calendar`).add({
        timestamp,
        method: 'manual'
      });
      setIsTodayValidated(true);
      uiStore.setIsTodayValidated(true);
      uiStore.setIntakeTime(time);
    } catch (error) {
      console.error('Error writing to Firestore', error);
    }
    handleCloseModal();
  };

  return (
    <Container style={{ paddingBottom: "40px", minHeight: 'calc(115vh - 60px)' }}>
      <h1 className={GlobalStyles.title}>
        Heute Pille validieren
      </h1>
      <DailyPill />
      <p className={styles.tip}>Du kannst den Funk-Aufkleber scannen <br />ohne die App zu öffnen</p>
      <Lottie animationData={tapAnimation} className={styles.lottie} />
      <div style={{
        position: 'absolute',
        bottom: 100,
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'
      }}>
        <Button
          variant="contained"
          id="pill_added_manually"
          fullWidth
          onClick={handleOpenModal}
          disabled={isTodayValidated}
          style={{
            backgroundColor: isTodayValidated ? '#F4F4F4' : '#F4F4F4',
            height: '50px',
            borderRadius: '10px',
            textTransform: 'none',
            fontWeight: 'bolder',
            fontSize: '1rem',
            boxShadow: 'none',
            lineHeight: "1rem",
            color: isTodayValidated ? '#8B8B90' : '#6260E6'
          }}
        >
          {isTodayValidated ? 'Bereits validiert' : 'Manuelle Eingabe der Pille hinzufügen'}
        </Button>
        <p className={styles.tip} style={{ color: 'rgb(150, 150, 150)', marginTop: '15px' }}>Du kannst nur die Einnahme deiner Pillen<br />für den aktuellen Tag aufzeichnen.</p>

      </div>
      <Modal isOpen={isModalOpen} onRequestClose={handleCloseModal} style={{ overlay: { backgroundColor: 'rgba(0, 0, 0, .75)' }, content: { top: '50%', left: '50%', right: 'auto', bottom: 'auto', marginRight: '-50%', transform: 'translate(-50%, -50%)', borderRadius: '20px', boxShadow: '0px 4px 16px rgba(0, 0, 0, 0.25)', maxWidth: '80%', padding: '35px 15px 15px', } }}>
        <img src={manualIcon} style={{ margin: '0 auto 20px', display: 'block', maxWidth: '100px' }} />
        <h2 style={{ fontSize: '1.3rem', fontWeight: '500', marginBottom: '15px', textAlign: 'center' }}>Bestätigung der Einnahme</h2>
        <p style={{ fontSize: '1rem', lineHeight: '1.1', marginBottom: '35px', textAlign: 'center', padding: '0 3%', color: '#353535' }}>Bist Du sicher, dass Du Deine Einnahme bestätigen möchtest?</p>
        <div style={{}}>
          <Button variant="contained" id="pill_added_manually-confirm" color="primary" onClick={handleValidateIntake} style={{ textTransform: 'none', fontSize: '1rem', fontWeight: '600', width: '100%', marginRight: '10px', backgroundColor: '#6260E6', color: '#FFF', marginBottom: '5px', borderRadius: '10px' }}>
            Heutige Pille bestätigen
          </Button>
          <Button variant="outlined" color="primary" onClick={handleCloseModal} style={{ textTransform: 'none', fontSize: '1rem', fontWeight: '400', width: '100%', border: 'none', color: '#8B8B90' }}>
            Abbrechen
          </Button>

        </div>
      </Modal>
    </Container>
  );
};

export default ScanView;
