import React from 'react';
import styles from './filtertag.module.css';
import { useStores } from '../../hooks/useStore';
import { useObserver } from 'mobx-react';
import ClearIcon from '@mui/icons-material/Clear';

function FilterTag({ tag, filter, color }) {
  const { postStore } = useStores();

  const handleTagSelect = () => {
    if (postStore.selectedTags.includes(tag)) {
      postStore.setSelectedTags(postStore.selectedTags.filter(t => t !== tag));
    } else {
      postStore.setSelectedTags([...postStore.selectedTags, tag]);
    }
  };

  return useObserver(() => (
    <div
      className={`${styles.tag} ${postStore.selectedTags.includes(tag) && color ? styles.selected : ''
        } ${styles.container}`}
      onClick={handleTagSelect}
    >
      <span className={styles.text}>{tag}</span>
      {
        filter ?
          postStore.selectedTags.includes(tag) ? <ClearIcon className={styles.clear} /> : false
          : false
      }
    </div>
  ));
}

export default FilterTag;
