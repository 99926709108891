import React, { useEffect } from 'react';
import styles from '../onboarding.module.css'
import { useStores } from '../../hooks/useStore';
import { observer } from "mobx-react-lite";

const MedScreen = observer(() => {
  const { userStore } = useStores();

  useEffect(() => {
    userStore.setMedication("Toremifene");
  }, [userStore]);

  const handleMedChange = (event) => {
    const value = event.target.value === '' ? null : event.target.value; // set to null if value is empty
    userStore.setMedication(value);
  };

  return(
    <div className={styles.container}>
      <h1 className={styles.title}>Welches Medikament nimmst Du?</h1>
      <p className={styles.description}>Wähle dein anti-hormonales Medikament aus.</p>
      <label className={styles.label}>Deine anti-hormonelle Therapie:
      <select className={styles.input} value={userStore.userMed} onChange={handleMedChange}>
        <option value="Toremifene">Toremifene</option>
        <option value="Tamoxifen">Tamoxifen</option>
        <option value="unsure">Ich weiß nicht</option>
      </select>
      </label>
    </div>
  );
})

export default MedScreen;

