import moment from 'moment';

const countConsecutiveDays = (timestamps) => {
  const uniqueTimestamps = Array.from(new Set(timestamps));
  const days = uniqueTimestamps
    .filter((timestamp) => moment(timestamp, 'M/D/YYYY, h:mm:ss A').isValid())
    .map((timestamp) => moment(timestamp, 'M/D/YYYY, h:mm:ss A').startOf('day'))
    .sort((a, b) => a - b);

  const today = moment().startOf('day');
  const yesterday = moment().subtract(1, 'days').startOf('day');

  let dayCounts = {};

  days.forEach((day) => {
    let formattedDay = day.format('M/D/YYYY');
    dayCounts[formattedDay] = (dayCounts[formattedDay] || 0) + 1;
  });

  let consecutiveDaysCount = 0;
  let currentDay = dayCounts[today.format('M/D/YYYY')] ? today : yesterday;

  while (dayCounts[currentDay.format('M/D/YYYY')]) {
    consecutiveDaysCount++;
    currentDay = currentDay.subtract(1, 'days');
  }

  return consecutiveDaysCount;
};

export default countConsecutiveDays;
