import React, { useState } from 'react';
import styles from './installscreen.module.css';
import ContentBlock from '../../components/ContentBlock';
import { useStores } from '../../hooks/useStore';
import { observer } from 'mobx-react';
import InstallVideo from '../../components/InstallVideo';
import AndroidIcon from '../../assets/icons/addtohome-android.svg';
import IosIcon from '../../assets/icons/addtohome-ios.svg';
import CloseIcon from '@mui/icons-material/Close';

const InstallScreen = observer(() => {
  const { uiStore, userStore } = useStores();
  const [platform, setPlatform] = useState('ios');

  const handlePlatformToggle = (selectedPlatform) => {
    setPlatform(selectedPlatform);
  };

  return (
    <div className={styles.container}>
      <div className={styles.wrapper}>
        {/* <button className={styles.closeBtn} onClick={() => userStore.setInstalledApp(true)} ><CloseIcon fontSize="small"/></button> */}
        {platform === 'ios' ? (
          <img className={styles.icon} src={IosIcon} alt="iOS" />
        ) : (
          <img className={styles.icon} src={AndroidIcon} alt="Android" />
        )}
        <h1 className={styles.installTitle}>Installiere die App auf deinem Startbildschirm.</h1>
        <p>
          Füge die App zu Deinem Startbildschirm hinzu und schließe das Onboarding ab, um mit der Dokumentation Deiner Tabletteneinnahme zu beginnen.
        </p>
        {platform === 'android' ? <p style={{marginTop: "10px", color: '#6260E6'}}>
          Bitte stellen Sie sicher, dass Sie es in Google Chrome öffnen.
        </p> : false}
        <div className={styles.platformToggle}>
          <button
            className={platform === 'ios' ? styles.selected : ''}
            onClick={() => handlePlatformToggle('ios')}
            id='homescreen_ios'
          >
            iOS
          </button>
          <button
            className={platform === 'android' ? styles.selected : ''}
            onClick={() => handlePlatformToggle('android')}
            id='homescreen_android'
          >
            Android
          </button>
        </div>
        <InstallVideo platform={platform} />
      </div>
    </div>
  );
});

export default InstallScreen;
