import React, { useState } from 'react';
import styles from '../onboarding.module.css'
import { useStores } from '../../hooks/useStore';

function TermsScreen() {
  const { userStore } = useStores();
  const [termsChecked, setTermsChecked] = useState(userStore.agreedToTerms);
  const [privacyChecked, setPrivacyChecked] = useState(userStore.agreedToPrivacyPolicy);

  const handleTermsChange = (event) => {
    const value = event.target.checked;
    setTermsChecked(value);
    userStore.setAgreedToTerms(value);
  };

  const handlePrivacyPolicyChange = (event) => {
    const value = event.target.checked;
    setPrivacyChecked(value);
    userStore.setAgreedToPrivacyPolicy(value);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Bedingungen &<br /> Datenschutz überprüfen</h1>
      <p className={`${styles.descriptionSmall} ${styles.fullWidth}`}>
      Indem ich die unten stehenden Vereinbarungen auswähle, habe ich die Nutzungsbedingungen überprüft und akzeptiert.
      </p>

      <label className={styles.termsBox}>
        <div className={styles.box}>
          <div className={styles.agree}>Ich stimme zu</div>
          Wir werden Deine Daten nur zu Forschungszwecken verwenden. Deine persönlichen Informationen werden geschützt und vertraulich behandelt.
        </div>
        <input
          type="checkbox"
          checked={termsChecked}
          onChange={handleTermsChange}
          className={styles.checkboxBox}
        />
      </label>
    </div>
  );
}

export default TermsScreen;
