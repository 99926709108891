import React, { useEffect, useState } from 'react';
import styles from "./streakcontainer.module.css";
import Lottie from "lottie-react";
import flame from "../../assets/lotties/streak-flame.json";
import moment from 'moment';
import 'moment/locale/de';
import getConsecutiveDaysCount from "../../utils/getConsecutiveDaysCount"

function StreakContainer({ timestamps }) {
    const [consecutiveDays, setConsecutiveDays] = useState(0);
    const [showNewWeekMessage, setShowNewWeekMessage] = useState(false);

    useEffect(() => {
        const dayTimestamps = timestamps.map((timestamp) => moment(timestamp, 'M/D/YYYY, h:mm:ss A').format('M/D/YYYY'));
        setConsecutiveDays(getConsecutiveDaysCount(dayTimestamps));

        moment.locale('de');
        const currentDayOfWeek = moment().format('dddd');
        setShowNewWeekMessage(currentDayOfWeek === 'Montag');
    }, [timestamps]);

    return (
        <>
            {showNewWeekMessage && (
                <div className={styles.newWeekMessage}>
                    Es ist eine neue Woche! Du schaffst das!
                </div>
            )}

            <div className={styles.container}>
                {consecutiveDays !== 0 && (
                    <Lottie animationData={flame} className={styles.animation} />
                )}
                <div className={styles.streakContainter}>
                    <span className={styles.days}><span>{consecutiveDays}</span> Tage Erfolgssträhne</span>
                    {consecutiveDays !== 0 && (
                        <span className={styles.description}>Weiter so!</span>
                    )}
                </div>
            </div>
        </>
    )
}

export default StreakContainer;
