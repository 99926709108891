import React from 'react';
import { DayPicker } from 'react-day-picker';
import 'react-day-picker/dist/style.css';
import styles from './calendarview.module.css';
import { de } from 'date-fns/locale';
import { format } from 'date-fns';

const CalendarView = ({timestamps}) => {
  
  const dates = timestamps;

  const highlightedDates = dates.map((date) => {
    return new Date(date);
  });

  // console.log(highlightedDates)

  const modifiers = {
    highlighted: highlightedDates,
  };

  const modifiersStyles = {
    highlighted: {
      backgroundColor: '#6260E6',
      color: 'white'
    }
  };

  const formatDay = (date) => format(date, 'M/D/YYYY, h:mm:ss A');

  return (
    <>
      <DayPicker
        style={{ margin: 0 }}
        numberOfMonths={12}
        formatDay={formatDay}
        hideHead
        classNames={{
          months: styles.months,
          month: styles.month,
          container: styles.container,
          week: styles.week,
          day: styles.day,
          today: styles.today,
          caption_label: styles.caption
        }}
        locale={de}
        modifiers={modifiers}
        modifiersStyles={modifiersStyles}
      />
    </>
  )
};

export default CalendarView;
