import { makeAutoObservable } from 'mobx';
import { collection, doc, setDoc, getDoc, DocumentSnapshot, updateDoc } from "firebase/firestore";
import { db } from "../firebase";

class UserStore {
  userId = '';
  userMed = undefined;
  agreedToTerms = false;
  agreedToPrivacyPolicy = false;
  userOnboarded = false;
  everydayReminder = false;
  forgetReminder = false;
  intakeTime = null;
  forgetTime = null;
  installedApp = false;
  loadedFromFirestore = false;
  deviceToken = false;
  timestamps = [];

  constructor() {
    makeAutoObservable(this);
    // Load saved state from local storage
    this.loadState();
  }

  setOnboarded(value) {
    this.userOnboarded = value;
    // console.log("userOnboarded", this.userOnboarded)

    this.saveState();
  }

  setMedication(value) {
    this.userMed = value;
    this.saveState();
  }

  setDeviceToken(value) {
    this.deviceToken = value;
    // console.log("forgetTime", this.forgetTime)
    this.saveState();
  }

  setIntakeTime(value) {
    this.intakeTime = value;
    // console.log("intakeTime", this.intakeTime)
    this.saveState();
  }

  setForgetTime(value) {
    this.forgetTime = value;
    // console.log("forgetTime", this.forgetTime)
    this.saveState();
  }

  setEverydayReminder(value) {
    this.everydayReminder = value;
    // console.log("everydayReminder", this.everydayReminder)
    this.saveState();
  }

  setForgetReminder(value) {
    this.forgetReminder = value;
    // console.log("forgetReminder", this.forgetReminder)
    this.saveState();
  }

  setAgreedToTerms(value) {
    this.agreedToTerms = value;
    // console.log("agreedToTerms", this.agreedToTerms)
    this.saveState();
  }

  setAgreedToPrivacyPolicy(value) {
    this.agreedToPrivacyPolicy = value;
    // console.log("agreedToPrivacyPolicy", this.agreedToPrivacyPolicy)
    this.saveState();
  }

  setUserId(value) {
    this.userId = value;
    localStorage.setItem('userId', encodeURIComponent(value));
    // console.log('user from setuser')
    this.saveState();
  }

  getUserId() {
    if (!this.userId) {
      this.userId = localStorage.getItem(decodeURIComponent('userId'));
    }
    return this.userId;
  }

  setInstalledApp(value) {
    this.installedApp = value;
    this.saveState();
    this.initFirebaseAndGTM();
  }

  async loadDataFromFirestore() {
    const userId = this.getUserId();
    if (userId) {
      const usersRef = collection(db, 'users');
      const userDocRef = doc(usersRef, userId);
      try {
        const userDoc = await getDoc(userDocRef);
        if (userDoc.exists()) {
          const userData = userDoc.data();
          this.userMed = userData.userMed;
          this.agreedToTerms = userData.agreedToTerms;
          this.agreedToPrivacyPolicy = userData.agreedToPrivacyPolicy;
          this.userOnboarded = userData.userOnboarded;
          this.everydayReminder = userData.everydayReminder;
          this.forgetReminder = userData.forgetReminder;
          this.intakeTime = userData.intakeTime;
          this.forgetTime = userData.forgetTime;
          this.installedApp = userData.installedApp;
          this.deviceToken = userData.deviceToken;
          this.saveState();
          return true; // user found in Firestore
        } else {
          console.log('User data not found in Firestore');
          return false; // user not found in Firestore
        }
      } catch (error) {
        console.error('Error loading data from Firestore', error);
        return false; // user not found in Firestore due to error
      }
    }
    return false; // no userId present
  }


  initialize() {
    const urlParams = new URLSearchParams(window.location.search);
    const userId = urlParams.get('user');
    if (userId) {
      // console.log('user from init')
      this.setUserId(userId);
      const usersRef = collection(db, 'users');
      const userDocRef = doc(usersRef, userId);
      getDoc(userDocRef)
        .then((docSnapshot) => {
          if (docSnapshot.exists()) {
            console.log("Document data:", docSnapshot.data());
            const data = docSnapshot.data();
            this.setMedication(data.userMed);
            this.setAgreedToTerms(data.agreedToTerms);
            this.setAgreedToPrivacyPolicy(data.agreedToPrivacyPolicy);
            this.setOnboarded(data.userOnboarded);
            this.setEverydayReminder(data.everydayReminder);
            this.setForgetReminder(data.forgetReminder);
            this.setIntakeTime(data.intakeTime);
            this.setForgetTime(data.forgetTime);
            this.setInstalledApp(data.installedApp);
            this.setDeviceToken(data.deviceToken);
          } else {
            console.log("No such document!");
          }
          this.setLoadedFromFirestore(true);
        })
        .catch((error) => {
          console.log("Error getting document:", error);
          this.setLoadedFromFirestore(true);
        });
    } else {
      this.setLoadedFromFirestore(true);
    }
  }

  setLoadedFromFirestore() {
    this.loadedFromFirestore = true;
  }

  async checkIfUserExists(userId) {
    try {
      const userDocRef = doc(db, "users", userId);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        return true;
      }
      return false;
    } catch (error) {
      console.error("Error checking if user exists", error);
      return false;
    }
  }

  async loadUserDataFromFirebase() {
    const userId = this.getUserId();
    if (!userId) {
      return;
    }

    const userDocRef = doc(db, "users", userId);
    const userDoc = await getDoc(userDocRef);

    if (!userDoc.exists()) {
      return;
    }

    const userData = userDoc.data();

    this.userMed = userData.userMed;
    this.agreedToTerms = userData.agreedToTerms;
    this.agreedToPrivacyPolicy = userData.agreedToPrivacyPolicy;
    this.userOnboarded = userData.userOnboarded;
    this.everydayReminder = userData.everydayReminder;
    this.forgetReminder = userData.forgetReminder;
    this.intakeTime = userData.intakeTime;
    this.forgetTime = userData.forgetTime;
    this.installedApp = userData.installedApp;
    this.deviceToken = userData.deviceToken;

    this.saveState();
  }

  async initFirebaseAndGTM() {
    const { userId, userMed, agreedToTerms, agreedToPrivacyPolicy, intakeTime, everydayReminder, forgetReminder, forgetTime, installedApp, userOnboarded, deviceToken } = this;
    const usersRef = collection(db, 'users');
    const userDocRef = doc(usersRef, userId);
    const time = new Date().getTime();
    const creationTime = new Date(time).toLocaleString();
    try {
      await setDoc(userDocRef, {
        userId,
        userMed,
        agreedToTerms,
        agreedToPrivacyPolicy,
        intakeTime,
        everydayReminder,
        forgetReminder,
        forgetTime,
        installedApp,
        userOnboarded,
        deviceToken,
        creationTime
      });
      console.log('User data added to Firestore');
    } catch (error) {
      console.error('Error adding user data to Firestore: ', error);
    }

    // Initialize Google Tag Manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'user_added',
      'user_id': userId
    });

    (function (w, d, s, l, i) {
      w[l] = w[l] || []; w[l].push({
        'gtm.start':
          new Date().getTime(), event: 'gtm.js'
      }); var f = d.getElementsByTagName(s)[0],
        j = d.createElement(s), dl = l != 'dataLayer' ? '&l=' + l : ''; j.async = true; j.src =
          'https://www.googletagmanager.com/gtm.js?id=' + i + dl; f.parentNode.insertBefore(j, f);
    })(window, document, 'script', 'dataLayer', 'GTM-NCTFF5X');

  }

  async changeNotifactions() {
    const { userId, intakeTime, everydayReminder, forgetReminder, forgetTime } = this;
    const usersRef = collection(db, 'users');
    const userDocRef = doc(usersRef, userId);
    const time = new Date().getTime();
    const timestamp = new Date(time).toLocaleString();
    try {
      await updateDoc(userDocRef, {
        intakeTime,
        everydayReminder,
        forgetReminder,
        forgetTime
      });
      await db.collection(`users/${userId}/notificationChanges`).add({
        timestamp,
        intakeTime,
        everydayReminder,
        forgetReminder,
        forgetTime
      });
      console.log('User data added to Firestore');
    } catch (error) {
      console.error('Error adding user data to Firestore: ', error);
    }

    // Initialize Google Tag Manager
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      'event': 'user_added',
      'user_id': userId
    })
  }

  async updateToken(deviceTokenData) {
    const { userId } = this;
    const usersRef = collection(db, 'users');
    const userDocRef = doc(usersRef, userId);
    const time = new Date().getTime();
    const timestamp = new Date(time).toLocaleString();
    try {
      await updateDoc(userDocRef, {
        deviceToken: deviceTokenData // Use the deviceTokenData passed as a parameter
      });
      console.log('Token updated in Firestore');
    } catch (error) {
      console.error('Error updating user token in Firestore: ', error);
    }
  }

  // async updateToken() {
  //   const { userId, deviceToken } = this;
  //   const usersRef = collection(db, 'users');
  //   const userDocRef = doc(usersRef, userId);
  //   const time = new Date().getTime();
  //   const timestamp = new Date(time).toLocaleString();
  //   try {
  //     this.setDeviceToken(deviceToken); // Wait for setDeviceToken() to complete
  //     await updateDoc(userDocRef, {
  //       deviceToken
  //     });
  //     console.log('Token updated in Firestore');
  //   } catch (error) {
  //     console.error('Error updating user token in Firestore: ', error);
  //   }
  // }

  // Save the state to local storage
  saveState() {
    for (const [key, value] of Object.entries(this)) {
      localStorage.setItem(key, encodeURIComponent(value));
    }
  }

  // Load the saved state from local storage
  loadState() {
    for (const key of Object.keys(this)) {
      const savedValue = localStorage.getItem(key);
    }
  }
}

export default UserStore;