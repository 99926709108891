import React, { useState, useEffect } from "react";
import moment from "moment";
import 'moment/locale/de';

import styles from "./weekcontainer.module.css";

import DayCheck from '../../components/DayCheck'
import { useStores } from '../../hooks/useStore';
import "moment/locale/de";

function WeekContainer({ onlyLabels, timestamps }) {
    const { uiStore } = useStores();
    uiStore.setCurrentDay();
    const [days, setDays] = useState([]);

    useEffect(() => {
        const startOfWeek = moment().startOf("isoWeek");

        const daysArray = Array.from({ length: 7 }, (_, i) => {
            const day = moment(startOfWeek).add(i, "days").locale("de");
            return {
                name: day.format("dd"),
                number: day.format("D"),
                date: day.format("M/D/YYYY")
            };
        });

        setDays(daysArray);
    }, []);

    return (
        <div className={styles.container}>
            <div className={styles.days}>
                {
                    days.map((day) => (
                        <div key={day.name} className={styles.day}>
                            <div
                                style={{
                                    backgroundColor: day.name === uiStore.currentDay ? 'black' : 'white',
                                    color: day.name === uiStore.currentDay ? 'white' : (day.name === 'Sa' || day.name === 'So' ? '#86868A' : 'black'),
                                }}
                                className={styles.dayLabel}
                            >
                                {day.name}
                            </div>

                            {onlyLabels ? false : <DayCheck timestamps={timestamps} day={day.date} />}
                        </div>
                    ))
                }
                
            </div>
            {onlyLabels ? false :
                    <p className={styles.overview} >Übersicht Tabletteneinnahme</p>
                }
        </div>
    )
}

export default WeekContainer;
