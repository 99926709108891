import { useState, useEffect } from 'react';
import styles from './dailypill.module.css';
import { observer } from 'mobx-react-lite';
import { Check } from '@mui/icons-material';
import PillIcon from '../../assets/icons/pill.svg';
import GlobalStyles from '../../globalstyles.module.css';
import { useStores } from '../../hooks/useStore';
import { db } from '../../firebase';

const DailyPill = observer(() => {
  const { userStore, uiStore } = useStores();
  const [earliestIntake, setEarliestIntake] = useState('');
  const [isTodayValidated, setIsTodayValidated] = useState(false);

  useEffect(() => {
    const today = new Date().toLocaleDateString('en-US', { timeZone: 'Europe/Berlin' });
    const query = db.collection(`users/${userStore.userId}/calendar`);
    query.get().then((querySnapshot) => {
      const todayIntakes = [];
      querySnapshot.forEach((doc) => {
        const docData = doc.data();
        if (docData.timestamp.includes(today)) {
          const intakeDate = new Date(docData.timestamp);
          const hours = intakeDate.getHours();
          const minutes = intakeDate.getMinutes();
          const formattedHours = hours < 10 ? `0${hours}` : hours;
          const formattedTime = `${formattedHours}:${minutes < 10 ? `0${minutes}` : minutes}`;
          todayIntakes.push(formattedTime);
        }
      });
      if (todayIntakes.length > 0) {
        const sortedIntakes = todayIntakes.sort();
        setEarliestIntake(sortedIntakes[0]);
        setIsTodayValidated(true);
        uiStore.setIsTodayValidated(true);
      }
    }).catch((error) => {
      console.error('Error fetching documents: ', error);
    });
  }, [userStore.userId]);

  return (
    <>
      <div className={styles.container}>
        <img className={styles.img} src={PillIcon} width={50} />
        <div className={styles.detailContainer}>
          <span className={styles.name}>{userStore.userMed === 'unsure' ? 'Deine Medikamente' : userStore.userMed}</span>
          <span className={styles.amount}>1 Mal/Tag</span>
        </div>
        <div className={styles.checkbox}>
          {uiStore.isTodayValidated && (
            <div className={styles.check} style={{ backgroundColor: '#6260E6' }}><Check style={{ color: 'white', width: '20px' }} /></div>
          )} 
          {uiStore.intakeTime ? <span className={styles.time}>{uiStore.intakeTime}</span> : 
          earliestIntake && (
            <span className={styles.time}>{earliestIntake}</span>
          )}
          
        </div>
      </div>
    </>
  );
});

export default DailyPill;
