import React from 'react';
import styles from './onboardingbutton.module.css';
import { ChevronLeft } from '@mui/icons-material';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';

const OnboardingButton = observer(({ onClick, type, label, buttonId, state, isHidden }) => {
  const { uiStore } = useStores();

  return (
    <button
      className={
        type === 'back' ?
          styles.backButton : type === 'later' ?
            styles.laterButton : state ?
              styles.nextButton : styles.inactive
      }
      onClick={onClick}
      id={type === 'back' ?
        'back' :
        `${buttonId}`
      }
      style={{ visibility: isHidden ? 'hidden' : 'visible' }}
    >
      {type === 'back' ?
        <ChevronLeft /> :
        `${label}`
      }
    </button>

  );
});

export default OnboardingButton;
