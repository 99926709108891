import { observer } from 'mobx-react-lite';
import React, { useState, useEffect } from 'react';
import styles from './votingform.module.css';
import globalStyles from '../../globalstyles.module.css';
import { db } from "../../firebase";

const VotingForm = observer((postId) => {
  const [vote, setVote] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  function handleVoteChange(event) {
    setVote(Number(event.target.value));
  }

  function handleSubmit(event) {
    event.preventDefault();
    if (vote > 0) {
      setSubmitted(true);

      // Get user ID from localStorage
      const userId = localStorage.getItem('userId');

      // Save vote to Firestore
      const now = new Date();
      const timestamp = now.toLocaleString('de-DE');
      const voteData = {
        score: vote,
        timestamp: timestamp
      };
      db.collection('users').doc(userId).collection('posts').doc(String(postId.post)).set(voteData, { merge: true })
        .then(() => {
          console.log('Vote saved to Firestore!');
        })
        .catch((error) => {
          console.error('Error saving vote to Firestore:', error);
        });
    }
  }

  useEffect(() => {
    // Get user ID from localStorage
    const userId = localStorage.getItem('userId');

    // Check if document exists
    db.collection('users').doc(userId).collection('posts').doc(String(postId.post)).get()
      .then((doc) => {
        if (doc.exists) {
          // Document exists, set submitted to true
          setSubmitted(true);
        }
      })
      .catch((error) => {
        console.error('Error checking if document exists:', error);
      });
  }, [postId]);

  if (submitted) {
    return <div>Vielen Dank für Ihre Stimme!</div>;
  }

  return (
    <>
      <hr className={globalStyles.devider} />
      <form onSubmit={handleSubmit} className={styles.container}>
        {postId.post == 'iosForNotifications' ? false :
          <h2 style={{ fontWeight: "600" }}>War dieser Artikel nützlich?</h2>
        }
        <div className={styles.votingForm} >
          <input className={styles.check} type="radio" value="1" id="option1" checked={vote === 1} onChange={handleVoteChange} />
          <label htmlFor="option1" className={styles.votingBlock}>1</label>

          <input className={styles.check} type="radio" value="2" id="option2" checked={vote === 2} onChange={handleVoteChange} />
          <label htmlFor="option2" className={`${styles.votingBlock}`}>2</label>

          <input id="option3" className={styles.check} type="radio" value="3" checked={vote === 3} onChange={handleVoteChange} />
          <label htmlFor="option3" className={styles.votingBlock}>3</label>

          <input className={styles.check} id="option4" type="radio" value="4" checked={vote === 4} onChange={handleVoteChange} />
          <label htmlFor="option4" className={styles.votingBlock}>4</label>

          <input className={styles.check} id="option5" type="radio" value="5" checked={vote === 5} onChange={handleVoteChange} />
          <label htmlFor="option5" className={styles.votingBlock}>5</label>

        </div>
        <div style={{ display: "flex", justifyContent: "space-between", color: "#C1C1C1", fontSize: ".8rem", marginTop: "10px" }}>
          <p>{postId.post == 'iosForNotifications' ? 'nicht hilfreich' : 'unbrauchbar'}</p>
          <p>sehr hilfreich</p>
        </div>
        <button className={styles.btn} type="submit">Absenden</button>
      </form>
    </>
  );
});

export default VotingForm;
