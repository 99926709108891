import styles from './motivationcomp.module.css'; // import your CSS file with custom styles
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';
import Smile from '../../assets/lotties/smile.json'
import Lottie from "lottie-react";
import smileIcon from '../../assets/icons/smile.svg'

const MotivationComp = observer(({ day }) => {
    const { uiStore } = useStores();

    return (
        <div className={styles.container}>
            <div className={styles.smileContainer}>
                <img src={smileIcon} alt="" className={styles.smile}/>
            </div>
            <p className={styles.progressQuote}>You took <span style={{ color: '#6260E6', fontWeight: 'bold' }}>100%</span> of your medication this week and with that you have lowered your risk of cancer.</p>
        </div>
    );
})

export default MotivationComp;