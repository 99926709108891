import styles from './installvideo.module.css';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';;

const InstallVideo = observer(({ platform }) => {
  const { uiStore } = useStores();

  return (
    <div className={styles.container}>
      {platform === 'ios' ? (
        <img className={styles.video} src="https://firebasestorage.googleapis.com/v0/b/lilly-c51cf.appspot.com/o/install%2Finstall-ios.gif?alt=media&token=58b214ec-9a6c-4ac6-b5b7-72126e3e8400" />
      ) : (
        <img className={styles.video} src="https://firebasestorage.googleapis.com/v0/b/lilly-c51cf.appspot.com/o/install%2Finstall-android.gif?alt=media&token=376a22d7-bd9a-4aa8-96d0-f410be7fa4b5" />
      )}
    </div>
  );
});

export default InstallVideo;
