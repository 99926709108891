import React, { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { useStores } from "../../hooks/useStore";
import MeditationImage from "../../assets/images/app/meditation.svg"
import styles from './mantracontainer.module.css'; // import your CSS file with custom styles
import Lottie from "lottie-react";
import Meditation from "../../assets/lotties/meditation.json";

const MantraContainer = observer(() => {
    const { mantraStore } = useStores();
    const [randomMantra, setRandomMantra] = useState("");

    useEffect(() => {
        if (mantraStore.mantras.length > 0) {
            const randomIndex = Math.floor(Math.random() * mantraStore.mantras.length);
            setRandomMantra(mantraStore.mantras[randomIndex].text);
        }
    }, [mantraStore.mantras]);

    return (
        <div className={styles.container}>

            {randomMantra ? (
                <>
                    <img src={MeditationImage} className={styles.img} alt="" />
                    {/* <Lottie animationData={Meditation} className={styles.img}  style={{ height: 150 }}/> */}
                    <p className={styles.quote}>{randomMantra}</p>
                </>
            ) : (
                <p>Loading mantras...</p>
            )}
        </div>
    );
});

export default MantraContainer;
