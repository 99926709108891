import { useState, useEffect } from 'react';
import styles from './openarticlecontainer.module.css';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';
import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';

const OpenArticleContainer = observer(({ post }) => {
    const navigate = useNavigate();
    const [showInstallButton, setShowInstallButton] = useState(false);
    const [deferredPrompt, setDeferredPrompt] = useState(null);

    useEffect(() => {
        const handleBeforeInstallPrompt = (e) => {
            // Prevent Chrome 67 and earlier from automatically showing the prompt
            e.preventDefault();
            // Stash the event so it can be triggered later
            setDeferredPrompt(e);
            // Show the install button
            setShowInstallButton(true);
        };

        window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);

        return () => {
            window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
        };
    }, []);

    const handleInstallButtonClick = () => {
        if (deferredPrompt) {
            // Show the install prompt
            deferredPrompt.prompt();
            // Wait for the user to respond to the prompt
            deferredPrompt.userChoice.then((choiceResult) => {
                if (choiceResult.outcome === 'accepted') {
                    console.log('User accepted the install prompt');
                } else {
                    console.log('User dismissed the install prompt');
                }
                // Reset the deferredPrompt variable
                setDeferredPrompt(null);
                // Hide the install button
                setShowInstallButton(false);
            });
        }
    };

    return (
        <div className={styles.container} post={post} >
            <img src={post.thumbnail} className={styles.thumbnail} />
            <div className={styles.text}>
                <h3 className={styles.title} >{post.title}</h3>
                <span className={styles.description}>{post.description}</span>
                <Button variant="contained" id={`explore_article${post.id}`} fullWidth onClick={() => navigate(`/info/${post.id}`)} style={{ backgroundColor: '#6260E6', height: '50px', borderRadius: '10px', textTransform: 'none', fontWeight: '600', fontSize: '1.1rem', boxShadow: 'none', marginTop: '15px' }} >
                    Den Artikel entdecken
                </Button>
                {showInstallButton && (
                    <Button variant="contained" fullWidth onClick={handleInstallButtonClick} style={{ backgroundColor: '#6260E6', height: '50px', borderRadius: '10px', textTransform: 'none', fontWeight: '600', fontSize: '1.1rem', boxShadow: 'none', marginTop: '15px' }} >
                        Installieren
                    </Button>
                )}
            </div>
        </div>
    );
});

export default OpenArticleContainer;
