import React, { useState } from 'react';
import styles from '../onboarding.module.css'
import { useStores } from '../../hooks/useStore';
import PillBox from '../../assets/images/onboarding/box.svg'

function NfcScreen() {
  return (
    <div className={styles.nfccontainer}>
      <h1 className={styles.title}>Richte Deinen Funk-Aufkleber ein.</h1>
      <p className={styles.description}>Befestige den Funk-Aufkleber an Deiner Tablettenbox.</p>
      {/* <img src={PillBox}  className={styles.img} /> */}
    </div>
  );
}

export default NfcScreen;
