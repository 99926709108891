import React from 'react';
import WeekContainer from '../../containers/WeekContainer';
import styles from "./dayview.module.css";
import { Button } from '@mui/material';
import DailyPill from '../../components/DailyPill';
import MotivationComp from '../../components/MotivationComp';
import StreakContainer from '../../containers/StreakContainer';
import MantraContainer from '../../containers/MantraContainer';
import { useNavigate } from 'react-router-dom';


function DayView({ timestamps }) {
    const navigate = useNavigate();
    return (
        <div className={styles.container}>
            <div>
                <WeekContainer timestamps={timestamps} />
            </div>


            <StreakContainer timestamps={timestamps} />

            <DailyPill />

            <Button variant="contained" fullWidth onClick={() => navigate('/scan')} style={{ backgroundColor: '#6260E6', height: '50px', borderRadius: '10px', textTransform: 'none', fontWeight: 'bolder', fontSize: '1.1rem', boxShadow: 'none' }} >
            Tap neue Tablette
            </Button>

            <MantraContainer/>

        </div>
    )
}

export default DayView;
