import { useState, useEffect } from 'react';
import { collection, getDocs } from "firebase/firestore";
import { db } from "../../firebase";
import CalendarView from '../CalendarView';
import { useStores } from '../../hooks/useStore';
import WeekContainer from '../../containers/WeekContainer';

function TimestampsView({timestamps}) {
    const { userStore } = useStores();

    return (
        <>

            <div style={{ marginBottom: '25px' }}>
                <WeekContainer onlyLabels={true} />

        
            </div>

            <CalendarView timestamps={timestamps} />
        </>

    )
}

export default TimestampsView;
