import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import styles from '../onboarding.module.css'
import { useStores } from '../../hooks/useStore';

function IdScreen() {
  const { userStore } = useStores();
  const [userId, setUserId] = useState('');
  const location = useLocation();

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const userParam = searchParams.get('user');
    if (userParam) {
      setUserId(userParam);
      userStore.setUserId(userParam);
    }
  }, [location.search]);

  const handleInput = (event) => {
    setUserId(event.target.value);
    userStore.setUserId(userId);
  };

  const handleBlur = () => {
    userStore.setUserId(userId);
  };

  const handleKeyDown = (event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Wie lautet Deine Nutzer-ID?</h1>
      <p className={styles.description}>Bitte trage hier Deine Benutzer-ID aus der Broschüre ein, die Du bekommen hast. Das hilft uns Deine Privatsphäre zu schützen.</p>
      <input
        type="text"
        placeholder="Deine Nutzer-ID"
        value={userId}
        onChange={handleInput}
        onBlur={handleBlur}
        className={styles.input}
        onKeyDown={handleKeyDown}
      />
    </div>
  );
}

export default IdScreen;