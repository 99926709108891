import { useState } from 'react';
import styles from './contentblock.module.css'; // import your CSS file with custom styles
import { observer } from 'mobx-react-lite';
import { useStores } from '../../hooks/useStore';
import FilterTag from '../FilterTag';
import { Link } from 'react-router-dom';

const ContentBlock = observer(({ post }) => {

    return (
        <Link to={`/info/${post.id}`} className={styles.container} post={post} >
            <img src={post.thumbnail} className={styles.thumbnail} />
            <div className={styles.text}>
                <h3 className={styles.title} >{post.title}</h3>
                {/* <span className={styles.description}>{post.description}</span> */}

                <div className={styles.tagBox}>
                    {post.tags.map(tag => (
                        <FilterTag tag={tag} key={tag} clickable={false} color={true} />
                    ))}
                </div>
            </div>
        </Link>
    );
})

export default ContentBlock;