import React, { useState } from 'react';
import styles from '../onboarding.module.css'
import { useStores } from '../../hooks/useStore';
import GlobalStyles from '../../globalstyles.module.css';

function ScheduleScreen() {
  const { userStore } = useStores();
  const [intakeTime, setIntakeTime] = useState(localStorage.getItem('intakeTime') || '');
  const [forgetTime, setForgetTime] = useState(localStorage.getItem('forgetTime') || '');
  const [everydayReminder, setEverydayReminder] = useState(userStore.everydayReminder);
  const [forgetReminder, setForgetReminder] = useState(userStore.forgetReminder);
  const [showForgetTime, setShowForgetTime] = useState(false);

  const handleEveryDayChange = (event) => {
    const value = event.target.checked;
    setEverydayReminder(value);
    userStore.setEverydayReminder(value);
  };

  const handleForgetChange = (event) => {
    const value = event.target.checked;
    setForgetReminder(value);
    userStore.setForgetReminder(value);
    setShowForgetTime(value);
    if (value === false) {
      setForgetTime(null);
      userStore.setForgetTime(null);
    }

  };

  const handleInputIntake = (event) => {
    const value = event.target.value;
    setIntakeTime(value);
    userStore.setIntakeTime(value);
  };

  const handleInputForget = (event) => {
    const value = event.target.value;
    setForgetTime(value);
    userStore.setForgetTime(value);
  };

  return (
    <div className={styles.container}>
      <h1 className={styles.title}>Erinnerungen festlegen</h1>
      <p className={styles.descriptionSmall}>Wann nimmst Du normalerweise Deine Tablette ein?</p>
      <div className={styles.fieldsContainer}>
        <label className={styles.label}>Wann nimmst Du normalerweise<br/>Deine Tablette ein?
          <input
            type="time"
            placeholder="08:00"
            value={intakeTime}
            onChange={handleInputIntake}
            className={styles.timeInput}
          />
        </label>
        <br />

        <label className={styles.checkbox} >
          Erinnere mich jeden Tag daran.
          <input
            type="checkbox"
            checked={everydayReminder}
            onChange={handleEveryDayChange}
            className={styles.checkboxBox}
          />
        </label>

        <hr className={GlobalStyles.devider} />

        <label className={styles.checkbox}>
          Erinnere mich, falls ich vergessen habe zu scannen.
          <p></p>
          <input
            id="forget-check"
            type="checkbox"
            checked={forgetReminder}
            onChange={handleForgetChange}
            className={styles.checkboxBox}
          />
        </label>
        {showForgetTime && (
          <label className={styles.labelSmall} id="forget-time">Um welche Uhrzeit möchtest du erinnert werden?
            <input
              id="forgetTime"
              type="time"
              placeholder="08:15"
              value={forgetTime}
              onChange={handleInputForget}
              className={styles.timeInput}
            />
          </label>
        )}
      </div>
    </div>
  );
}

export default ScheduleScreen;
